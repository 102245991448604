import React from "react";
import Lottie from "react-lottie";
// import error from "./assets/error.json";
import success from "./assets/success.json";
import Axios from "axios";

Axios.defaults.baseURL = "http://manage.visalreinsurance.com";

const path = "/api/verifydocument";

export default function App() {
  const [success, setSuccess] = React.useState(false);
  const enc_type = window.location.pathname;

  return (
    <div className="container mt-4 py-4">
      {!success && (
        <Form
          enc_type={enc_type.substr(1, enc_type.length)}
          setStatus={(s) => setSuccess(s)}
        />
      )}
      {success && <Success />}
    </div>
  );
}

function Form({ enc_type, setStatus }) {
  const [phone, setPhone] = React.useState("");
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [warning, setWarning] = React.useState(false);
  const handleSend = (evt) => {
    evt.preventDefault();
    setError(false);
    if (phone.length < 10) {
      setWarning(true);
      return;
    } else {
      setWarning(false);
    }
    setLoading(true);
    Axios.post(path, {
      enc_type,
      client_phone_number: phone,
    })
      .then((res) => {
        console.log(res.data);
        setStatus(true);
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };
  return (
    <div className="row h-full justify-content-center">
      <div className="col-md-8 col-lg-6 col-xl-5">
        <div className="card">
          <div className="bg-soft-primary">
            <div className="row">
              <div className="col-12">
                <div className="text-primary p-4">
                  <h5 className="text-primary">Welcome !</h5>
                  <p>Provide detail to verify document</p>
                </div>
              </div>
              <div className="col-5 align-self-end"></div>
            </div>
          </div>
          <form onSubmit={handleSend} className="card-body">
            <div className="row">
              <div className="form-group alert alert-warning">
                <p>To guarantee document authentication, provide your phone number in the international standard form <br/>
                  <ul>
                    <li>Country code: 233</li>
                    <li>Phone number: 274488676</li>
                  </ul>
                  Example: 233274488676
                </p>
              </div>
            </div>
            {error && (
              <div className="alert alert-danger">
                Contact the system administrator at VISAL RE
              </div>
            )}
            {warning && (
              <div className="alert alert-warning">
                phone number cannot be less than 10
              </div>
            )}
            <div className="form-group">
              <input
                value={enc_type}
                type="hidden"
                className="form-control"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Phone number</label>
              <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="tel"
                className="form-control"
                placeholder="Eg. 233274488676"
                required
              />
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-block btn-primary">
                {!loading ? (
                  "Verify"
                ) : (
                  <>
                    {" "}
                    <span className="bx bx-hourglass bx-spin mr-2"></span>{" "}
                    verifying...{" "}
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function Success() {
  return (
    <div className="row justify-content-center">
      <div className="col-md-8 col-lg-6 col-xl-5">
        <div className="card">
          <div className="card-body">
            <Lottie
              options={{ animationData: success, loop: false }}
              height={200}
              width={200}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

// function Error() {
//   return (
//     <div className="row justify-content-center">
//       <div className="col-md-8 col-lg-6 col-xl-5">
//         <div className="card">
//           <div className="card-body">
//             <Lottie
//               options={{ animationData: error, loop: false }}
//               height={200}
//               width={200}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
